/**
 * 
 * @param {int} idDossier 
 * @returns {string} GraphQL Query
 */
export function getDossierQuery(idDossier) {
    return `DossierQuery(id: ${idDossier}){
        id
        creationDate
        status
        virtualState
        historyStatus {
            status
            date
        }
        rdv {
            creationDate
            status
            text
            dateStart
            dateEnd
            type
            links {
                type
                id
            }
        }
        links {
            type
            id
        }
    }`;
}

/**
 * 
 * @param {int} dossierId 
 * @param {int} clientId 
 * @returns {string} GraphQL Query
 */
export function getDossierCoLoanerQuery(dossierId, clientId) {
    return `DossierQuery(id: ${dossierId}){
        id
        links {
            type
            id
        }
        coLoaner(clientId: ${clientId}, idDossier: ${dossierId}) {
            id
            firstname
            maidenSurname
            lastname
            contact {
                phones
                fax
                email
                acceptEmailing
                acceptSms
                acceptPartners
            }
        }
    }`;
}
