import {getInstance} from "@/auth";

export default (audience, scope) => {
  let getToken = async function () {
    let token = null;

    try {
      token = await getInstance().getTokenSilently({
        audience: audience,
        scope: scope
      });

      return token;
    } catch (e) {
      if (e.error === 'login_required') {
        getInstance().logout();
      } else if (e.error === 'consent_required') {
        return await getInstance().getTokenWithPopup({
          audience: audience,
          scope: scope
        });
      }
    }
  }

  return { getToken }
}
