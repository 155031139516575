import DossierApiService from '../../services/api/dossier/dossier.api';
import AuthApiService from '../../services/api/auth.api';
import { getInstance } from '../../auth/authWrapper';
import { getDossierAuthorization } from '../../services/api/dossier/dossier.authorizations';
import { SET_DOSSIERS } from '../mutations/dossier.types';

const state = () => ({
    dossiers: [],
})

const mutations = {
    [SET_DOSSIERS] (state, payload) {
        state.dossiers = payload;
    },
}

const actions = {
    /**
     * 
     * @param {*} context 
     * @returns 
     */
    getMainDossier(context) {
        const authService = getInstance();
        return actions.getDossier(context, authService.getUserDossierId());
    },
    /**
     * 
     * @param {*} context 
     * @param int idDossier 
     * @returns 
     */
    getDossier(context, idDossier) {
        return new Promise((resolve) => {
            let authorization = getDossierAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DossierApiService(token).getDossier(idDossier)
            ).then((response) => {
                resolve(response.data.data.DossierQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    }
}

const getters = {
    getDossiers: (state) => state.dossiers,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
