import axios from '../../api';
import {
    getUserQuery,
    getUserSuperiorsQuery,
    unblockMutation
} from '../../graphql/queries/user.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get user
     * @param {int} idUser 
     * @returns 
     */
    let getUser = async function (idUser) {
        const data = JSON.stringify({
            query: `query {
                ${ getUserQuery(idUser) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'USER_GET_ONE'
            },
            data: data
        };
        return axios(config);
    }

    /**
     * Get user
     * @param {int} idUser
     * @param {string|null} groupName
     * @returns 
     */
    let getUserSuperiors = async function (idUser, groupName) {
        const data = JSON.stringify({
            query: `query {
                ${ getUserSuperiorsQuery(idUser) }
            }`,
            variables: {}
        });
        let headers = {
            'Content-Type': 'application/json'
        };
        if (groupName) {
            headers['eq'] = `{ "$Superior.GroupUser.Group.libelle_short$": "${groupName}" }`;
        }
        const config = {
            method: 'post',
            headers: headers,
            data: data
        };
        return axios(config);
    }

    /**
     * Unblock user
     *
     * @param string applicationId
     * @param string applicationName
     * @param string email
     * @returns 
     */
    let unblock = async function (applicationId, applicationName, email) {
        const data = JSON.stringify({
            query: unblockMutation(
                applicationId,
                applicationName,
                email
            ),
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    return { getUser, getUserSuperiors, unblock }
}
