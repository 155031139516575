import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './dossier_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getDossierAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOSSIER_AUDIENCE, SCOPES.DOSSIER_GET);
}
