import AuthApiService from '../../services/api/auth.api';
import LocalApiService from '../../services/api/local/local.api';
import {
    getLocalAuthorization
} from '../../services/api/local/local.authorizations';
import { SET_LOCALS } from '../mutations/local.types';

const state = () => ({
    locals: [],
})

const mutations = {
    [SET_LOCALS] (state, payload) {
        state.locals = payload;
    },
}

const actions = {
    /**
     * 
     * @param {*} context 
     * @param int idLocal 
     * @returns 
     */
    getLocal(context, idLocal) {
        return new Promise((resolve) => {
            let authorization = getLocalAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                LocalApiService(token).getLocal(idLocal)
            ).then((response) => {
                resolve(response.data.data.LocalQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    }
}

const getters = {
    getLocals: (state) => state.locals,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
