import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './cgu_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getCurrentCguAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CGU_AUDIENCE, SCOPES.CURRENT_CGU);
}

/**
 * 
 * @returns {Authorization}
 */
export function getCurrentDocumentAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CGU_AUDIENCE, SCOPES.CURRENT_DOC);
}
