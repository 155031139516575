import axios from '../../api';
import {
    getCurrentCguQuery,
    getCurrentDocumentQuery
} from '../../graphql/queries/cgu.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get current CGU
     * @returns 
     */
    let getCurrentCgu = async function () {
        const data = JSON.stringify({
            query: `query {
                ${ getCurrentCguQuery() }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    /**
     * Get current CGU content/document
     * @returns 
     */
     let getCurrentDocument = async function () {
        const data = JSON.stringify({
            query: `query {
                ${ getCurrentDocumentQuery() }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
            /*responseType: 'arraybuffer',
            transformResponse: [function (data) {
                let blob = new Blob([data], { type: 'application/pdf' })
                return URL.createObjectURL(blob)
            }]*/
        };
        return axios(config);
    }

    return { getCurrentCgu, getCurrentDocument }
}
