/**
 * @param {int} idLocal
 * @returns {string} GraphQL Query
 */
 export function getLocalQuery(idLocal) {
    return `LocalQuery(id: ${idLocal}){
        id
        type
        address {
            type
            label
            postalCode
            town
            country
        }
    }`;
}