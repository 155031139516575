import { createRouter, createWebHistory } from "vue-router";
import { authGuard } from "@/auth";
import {
  ROOT_PATH,
  HOME_PATH,
  BLOCKED_ACCOUNT_PATH,
  FIRST_CONNECTION_PATH,
  LOGIN_PATH,
  CONSTITUTE_FOLDER_PATH,
  TRACK_FOLDER_PATH,
  MY_ADVISOR_PATH,
  MY_PROFILE_PATH,
} from "./pathes";

const routes = [
  {
    path: ROOT_PATH,
    name: "",
    component: () => import("../views/Home.vue"),
    beforeEnter: authGuard,
  },
  {
    path: HOME_PATH,
    name: "Home",
    component: () => import("../views/Home.vue"),
    beforeEnter: authGuard,
  },
  {
    path: LOGIN_PATH,
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: BLOCKED_ACCOUNT_PATH,
    name: "BlockedAccount",
    component: () => import("../views/BlockedAccount.vue"),
  },
  {
    path: FIRST_CONNECTION_PATH,
    name: "First connection",
    component: () => import("../views/FirstConnection.vue"),
  },
  {
    path: CONSTITUTE_FOLDER_PATH,
    name: "Constituer dossier",
    component: () => import("../views/ConstituteFolder.vue"),
    beforeEnter: authGuard,
  },
  {
    path: TRACK_FOLDER_PATH,
    name: "Suivre dossier",
    component: () => import("../views/TrackFolder.vue"),
    beforeEnter: authGuard,
  },
  {
    path: MY_ADVISOR_PATH,
    name: "Mon conseiller",
    component: () => import("../views/MyAdvisor.vue"),
    beforeEnter: authGuard,
  },
  {
    path: MY_PROFILE_PATH,
    name: "Mon profil",
    component: () => import("../views/MyProfile.vue"),
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    authGuard(to, from, next);
  } else {
    next();
  }
});

export default router;
