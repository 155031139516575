import axios from '../../api';
import { getMessagesHistoryQuery } from '../../graphql/queries/message.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get message history
     * @param int idDossier 
     * @returns 
     */
    let getMessagesHistory = async function (idDossier) {
        const data = JSON.stringify({
            query: `query {
                ${ getMessagesHistoryQuery(idDossier) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    /**
     * 
     * @param int idDossier 
     * @param string message 
     * @param int contactId
     * @returns 
     */
    let sendMesage = async function (idDossier, message, contactId) {
        const data = JSON.stringify({
            query: `
                mutation {
                    CoreSendMessageMutation(message: "${message}", idDossier: ${idDossier}, listIdContact: [${contactId == null ? [] : contactId}])
                }
            `,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    return { getMessagesHistory, sendMesage }
}
