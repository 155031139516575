import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './user_scopes';

/**
 *
 * @returns {Authorization}
 */
export function getUserAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_USER_AUDIENCE, SCOPES.USER_GET);
}

/**
 *
 * @returns {Authorization}
 */
export function getUserSuperiorsAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_USER_AUDIENCE, SCOPES.SUPERIOR_LIST);
}
