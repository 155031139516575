import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './local_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getLocalAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_LOCAL_AUDIENCE, SCOPES.LOCAL_GET);
}
