import { createStore } from 'vuex'
import cgu from './modules/cgu.module';
import client from './modules/client.module';
import document from './modules/document.module';
import dossier from './modules/dossier.module';
import local from './modules/local.module';
import message from './modules/message.module';
import timeline from './modules/timeline.module';
import user from './modules/user.module';

const store = createStore({
    state : {
        isConnected : false,
        count: 0,
        token: null,
        userInfo : {
            "id" : "",
            "username" : "",
            "firstName" : "",
            "lastName" : "",
            "fullName" : ""
        },
        didReadCGU : true,
    },

    getters:{
        getIsConnected(state) {
            return state.isConnected;
        },
        getToken: (state) => state.token,
        getUserFullName(state) {
            return state.userInfo.fullName;
        },
        getDidReadCGU(state) {
            return state.didReadCGU;
        }
    },

    mutations: {
        setIsConnected(state, value){
            state.isConnected = value;
        },
        setToken(state, payload) {
            state.token = payload;
        },
        setUserFullname(state, value){
            state.userInfo.fullName = value;
        },
        setDidReadCGU(state, value){
            state.didReadCGU = value;
        }
    },
    
    actions: {
        setIsConnected(state, value){
           this.commit(state, value);
        },
        setUserFullname(state, value){
            this.commit(state, value);
        },
        setDidReadCGU(state, value){
            state.didReadCGU = value;
        } 
    },

    modules: {
        cgu,
        client,
        document,
        dossier,
        local,
        message,
        timeline,
        user
    }
})

export default store;
