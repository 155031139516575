import { getInstance } from "./authWrapper";
import { watch } from 'vue';

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    next({
      name : "Login"
    });
  };

  if (!authService.loading.value) {
    return fn();
  }

  watch(authService.loading, loading => {
    if (loading === false) {
      return fn();
    }
  });
};