import AuthApiService from '../../services/api/auth.api';
import { getInstance } from '../../auth/authWrapper';
import ClientApiService from '../../services/api/client/client.api';
import {
    getClientAuthorization,
    getClientLastTermAuthorization,
    setClientContactAcceptAuthorization,
    setClientPartnersAcceptAuthorization,
    setClientLastLoginAuthorization,
    setClientTermAcceptAuthorization,
    deactivateClientAuthorization,
    changePasswordAuthorization
} from '../../services/api/client/client.authorizations';
import { SET_MAIN_CLIENT, SET_CO_CLIENT } from '../mutations/client.types';

const state = () => ({
    mainClient: null,
    coClient: null,
})

const mutations = {
    [SET_MAIN_CLIENT] (state, payload) {
        state.mainClient = payload;
    },
    [SET_CO_CLIENT] (state, payload) {
        state.coClient = payload;
    },
}

const actions = {
    /**
     *
     * @param {*} context
     * @returns
     */
    getMainClient(context) {
        const authService = getInstance();
        return actions.getClient(context, {
            idClient: authService.getUserClientId(),
            idDossier: authService.getUserDossierId(),
            mutationType: SET_MAIN_CLIENT
        });
    },
    /**
     *
     * @param {*} context
     * @param {Object} payload
     * @param {int} payload.idClient
     * @param {int} payload.idDossier
     * @param {string} payload.mutationType
     * @returns
     */
    getClient(context, { idClient, idDossier, mutationType }) {
        return new Promise((resolve) => {
            let authorization = getClientAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).getClient(idClient, idDossier)
            ).then((response) => {
                if (mutationType) {
                    context.commit(mutationType, response.data.data.ClientQuery);
                }
                resolve(response.data.data.ClientQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     * @param {*} context
     * @returns
     */
    getMainClientLastTerm(context) {
        const authService = getInstance();
        return actions.getClientLastTerm(context, authService.getUserClientId());
    },
    /**
     *
     * @param {*} context
     * @param int idClient
     * @returns
     */
    getClientLastTerm(context, idClient) {
        return new Promise((resolve) => {
            let authorization = getClientLastTermAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).getClientLastTerm(idClient)
            ).then((response) => {
                resolve(response.data.data.LastTermQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     * @param {object} context
     * @param {object} payload
     * @param {boolean} payload.acceptEmailing
     * @param {boolean} payload.acceptSms
     * @returns
     */
    setClientContactAccept(context, { acceptEmailing, acceptSms } = {} ) {
        const authService = getInstance();
        const idClient = authService.getUserClientId();
        return new Promise((resolve) => {
            let authorization = setClientContactAcceptAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).setClientContactAccept(idClient, acceptEmailing, acceptSms)
            ).then((response) => {
                resolve(response.data.data.ClientContactAcceptMutation);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     * @param {object} context
     * @param {object} payload
     * @param {boolean} payload.acceptPartners
     * @returns
     */
    setClientPartnersAccept(context, { acceptPartners } = {} ) {
        const authService = getInstance();
        const idClient = authService.getUserClientId();
        return new Promise((resolve) => {
            let authorization = setClientPartnersAcceptAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).setClientPartnersAccept(idClient, acceptPartners)
            ).then((response) => {
                resolve(response.data.data.ClientAcceptPartnersMutation);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     * @param {object} context
     * @param {object} payload
     * @param {int} payload.idTerm
     * @returns
     */
    setClientTermAccept(context, {idTerm}) {
        const authService = getInstance();
        const idClient = authService.getUserClientId();
        return new Promise((resolve) => {
            let authorization = setClientTermAcceptAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).setClientTermAccept(idClient, idTerm)
            ).then((response) => {
                const dataTerm = response.data.data.TermAcceptMutation;
                const isOk = dataTerm === 'OK';
                if (!isOk) {
                    console.error(dataTerm);
                    alert(dataTerm);
                }
                resolve(isOk);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    setClientLastLogin() {
        const authService = getInstance();
        const idClient = authService.getUserClientId();
        return new Promise((resolve) => {
            let authorization = setClientLastLoginAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).setClientLastLogin(idClient)
            ).then((response) => {
                const data = response.data.data.ClientLastLoginUpdateMutation;
                const isOk = data === 'OK';
                if (!isOk) {
                    console.error(data);
                    alert(data);
                }
                resolve(isOk);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     * @param {object} context
     * @param {object} payload
     * @returns
     */
    deactivateClient() {
        const authService = getInstance();
        const email = authService.user.email;
        const auth0Id = authService.user.sub;
        return new Promise((resolve) => {
            let authorization = deactivateClientAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).deactivateClient(email, auth0Id)
            ).then((response) => {
                const data = response.data.data.ClientDeactivateUserMutation;
                resolve(data);
                authService.logout();
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * @param {object} context
     * @param {object} payload
     * @param {string} payload.password
     * @returns
     */
    changePassword(context, {password}) {
        const authService = getInstance();
        const auth0Id = authService.user.sub;
        return new Promise((resolve) => {
            let authorization = changePasswordAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                ClientApiService(token).changePassword(auth0Id, password)
            ).then((response) => {
                const data = response.data.data.ClientChangePasswordMutation;
                const isOk = data === 'Password changed';
                if (!isOk) {
                    console.error(data);
                    alert(data);
                }
                resolve(isOk);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    }
}

const getters = {
    getMainClient: (state) => state.mainClient,
    getCoClient: (state) => state.coClient,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
