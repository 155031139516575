import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './document_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getDocumentContentAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.CONTENT_GET);
}

/**
 * 
 * @returns {Authorization}
 */
export function getDocumentListByDossierAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.DOCUMENT_LIST);
}

/**
 * 
 * @returns {Authorization}
 */
export function getDocumentSigningByDossierAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.SIGNING_ALL);
}

/**
 * 
 * @returns {Authorization}
 */
export function getDocumentSigningLinkAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.DOCUMENT_GET);
}

/**
 * 
 * @returns {Authorization}
 */
export function getLegalDocumentListByDossierAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.LEGAL_LIST);
}

/**
 * 
 * @returns {Authorization}
 */
export function getUploadDocumentAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.CONTENT_POST);
}

/**
 * 
 * @returns {Authorization}
 */
export function patchLegalDocumentAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_DOCUMENT_AUDIENCE, SCOPES.LEGAL_PATCH);
}
