const client_scopes = {
    // Client
    CLIENT_LIST : 'client:client:list', // '/clients'
    CLIENT_GET : 'client:client:get', // '/client/id'
    CLIENT_PATCH : 'client:client:patch', // '/client/id'
    CLIENT_PUT : 'client:client:put', // '/client/id'
    CLIENT_LAST_LOGIN_PUT: 'client:lastlogin:put', // '/client/user/last_login'
    DEACTIVATION: 'client:user:deactivate', // '/client/deactivation'
    USER_CREATE : 'client:user:create', // '/client/id/create_account'
    USER_CHECK : 'client:user:check', // '/client/user/rgpdcheck'
    USER_LAST : 'client:user:last', // '/client/user/last_login'
    PASSWORD_CREATE : 'client:password:create', // '/client/create_password'
    PASSWORD_RENEW : 'client:password:renew', // '/client/renew_password'
    PASSWORD_EMAIL : 'client:password:email', // '/client/renew_password/email'
    PASSWORD_UPDATE : 'client:password:update', // '/client/update_password'
    TERM_LAST : 'client:term:last', // '/client/id/terms/last'
    TERM_ACCEPT : 'client:term:accept', // '/client/id/terms/accept'
    TERM_LIST : 'client:term:list' // '/client/id/terms'
};

export default client_scopes;
