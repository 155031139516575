import { Authorization } from '../../graphql/builder/authorization';
import erp_scopes from '../erp_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getTimelineStatusAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_ERP_SERVICE_AUDIENCE, erp_scopes.TIMELINE_STATUS);
}
