import TimelineApiService from '../../services/api/timeline/timeline.api';
import AuthApiService from '../../services/api/auth.api';
import { getInstance } from '../../auth/authWrapper';
import { getTimelineStatusAuthorization } from '../../services/api/timeline/timeline.authorizations';
import { SET_TIMELINE_STATUS } from '../mutations/timeline.types';

const state = () => ({
  timelineStatus: null,
})

const mutations = {
  [SET_TIMELINE_STATUS] (state, payload) {
    state.timelineStatus = payload;
  },
}

const actions = {
  /**
   * 
   * @param {*} context 
   * @param {*} payload 
   * @returns 
   */
  getTimelineStatusByMainDossier(context) {
    context.commit(SET_TIMELINE_STATUS, null)

    const authService = getInstance();

    return new Promise((resolve) => {
      const authorization = getTimelineStatusAuthorization();
      AuthApiService(authorization.audience, authorization.scope).getToken().then((token) => {
        TimelineApiService(token).getTimelineStatus(authService.getUserDossierId())
          .then((response) => {
            actions.commitTimelineStatus(context, response.data.data.TimelineStatusQuery);
            resolve(response.data.data.TimelineStatusQuery);
          })
          .catch(() => { })
      })
    })
  },
  commitTimelineStatus(context, timelineStatus) {
    context.commit(SET_TIMELINE_STATUS, timelineStatus);
  }
}

const getters = {
  getTimelineStatus: (state) => state.timelineStatus,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
