import axios from '../../api';
import { getLocalQuery } from '../../graphql/queries/local.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get local
     * @param int idLocal 
     * @returns 
     */
    let getLocal = async function (idLocal) {
        const data = JSON.stringify({
            query: `query {
                ${ getLocalQuery(idLocal) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'LOCAL_GET_ONE'
            },
            data: data
        };
        return axios(config);
    }

    return { getLocal }
}
