import AuthApiService from '../../services/api/auth.api';
import UserApiService from '../../services/api/user/user.api';
import { getUserAuthorization, getUserSuperiorsAuthorization } from '../../services/api/user/user.authorizations';
import { SET_ADVISOR, SET_USERS } from '../mutations/user.types';

const state = () => ({
    advisor: null,
    users: [],
})

const mutations = {
    [SET_USERS] (state, payload) {
        state.users = payload;
    },
    [SET_ADVISOR] (state, user) { state.advisor = user }
}

const actions = {
    /**
     *
     * @param {*} context
     * @param int idUser
     * @returns
     */
    getUser(context, idUser) {
        return new Promise((resolve) => {
            let authorization = getUserAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                UserApiService(token).getUser(idUser)
            ).then((response) => {
                resolve(response.data.data.UserQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     * @param {*} context
     * @param {object} payload
     * @param {int} payload.userId
     * @param {string|null} payload.groupName
     * @returns
     */
    getUserSuperiors(context, { userId, groupName }) {
        return new Promise((resolve) => {
            let authorization = getUserSuperiorsAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                UserApiService(token).getUserSuperiors(userId, groupName)
            ).then((response) => {
                resolve(response.data.data.UserSuperiorsQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     *
     */
    setAdvisor({ commit }, user) {
        commit(SET_ADVISOR, user)
    },
    /**
     *
     * @param {*} context
     * @param {object} payload
     * @param {string} payload.applicationId
     * @param {string} payload.applicationName
     * @param {string} payload.email
     * @returns
     */
    unblock(context, {applicationId, applicationName, email}) {
        return new Promise((resolve) => {
            UserApiService('').unblock(applicationId, applicationName, email)
                .then((response) => {
                    resolve(response.data.data.UserUnblockMutation);
                }).catch((err) => {
                    console.error(err);
                    alert('Merci de contacter votre commercial');
                });
        });
    }
}

const getters = {
    getAdvisor: (state) => state.advisor,
    getUsers: (state) => state.users,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
