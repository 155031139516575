export class Authorization {
    /**
     * 
     * @param {string} audience 
     * @param {string|string[]} scope 
     */
    constructor(audience, scope) {
        this.audience = audience;
        this.scope = scope;
    }
}
