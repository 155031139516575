/**
 * 
 * @returns {string} GraphQL Query
 */
export function getCurrentCguQuery() {
    return `CGUCurrentQuery {
        id
    }`;
}

/**
 * 
 * @returns {string} GraphQL Query
 */
 export function getCurrentCguAndClientLastTermQuery($clientId) {
    return `CGUCurrentQuery {
        id
        clientLastTerm(id: ${$clientId}) {
            id
        }
    }`;
}

/**
 * 
 * @returns {string} GraphQL Query
 */
 export function getCurrentDocumentQuery() {
    return `CGUCurrentDocumentQuery {
        fileContent
    }`;
}