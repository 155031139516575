import axios from '../../api';
import {
    getClientQuery,
    getClientLastTermQuery
} from '../../graphql/queries/client.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get client
     * @param int idClient
     * @param int idDossier
     * @returns
     */
    let getClient = async function (idClient, idDossier) {
        const data = JSON.stringify({
            query: `query {
                ${ getClientQuery(idClient, idDossier) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'CLIENT_GET_ONE'
            },
            data: data
        };
        return axios(config);
    }

    let getClientLastTerm = async function (idClient) {
        const data = JSON.stringify({
            query: `query {
                ${ getClientLastTermQuery(idClient) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'CLIENT_GET_LAST_CGU'
            },
            data: data
        };
        return axios(config);
    }

    /**
     * Update client contact accept (emailing, sms)
     * @param int idClient
     * @param boolean acceptEmailing
     * @param boolean acceptSms
     * @returns
     */
    let setClientContactAccept = async function (idClient, acceptEmailing, acceptSms) {
        const data = JSON.stringify({
            query: `mutation {
                ClientContactAcceptMutation(id: ${idClient}, acceptEmailing: ${acceptEmailing}, acceptSms: ${acceptSms}){
                    id
                    contact {
                        acceptEmailing
                        acceptSms
                    }
                }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'CLIENT_PATCH_ONE'
            },
            data: data
        };
        return axios(config);
    }

    /**
     * Update client partners accept
     * @param int idClient
     * @param boolean acceptPartners
     * @returns
     */
    let setClientPartnersAccept = async function (idClient, acceptPartners) {
        const data = JSON.stringify({
            query: `mutation {
                ClientAcceptPartnersMutation(id: ${idClient}, acceptPartners: ${acceptPartners}){
                    id
                    contact {
                        acceptPartners
                    }
                }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    /**
     *
     * @param int idClient
     * @param int idTerm
     * @returns
     */
    let setClientTermAccept = async function (idClient, idTerm) {
        const data = JSON.stringify({
            query: `mutation {
                TermAcceptMutation(idClient: ${idClient}, idTerm: ${idTerm})
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'CLIENT_VALIDATE_CGU'
            },
            data: data
        };
        return axios(config);
    };

    let setClientLastLogin = async function (idClient) {
        const data = JSON.stringify({
            query: `mutation {
                ClientLastLoginUpdateMutation(clientId: ${idClient})
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'X-AUTH-ROLES': 'USER_PUT_LAST_LOGIN'
            },
            data: data
        };
        return axios(config);
    }

    /**
     *
     * @param {string} email
     * @param {string} auth0Id
     * @returns
     */
    let deactivateClient = async function (email, auth0Id) {
        const data = JSON.stringify({
            query: `mutation {
                ClientDeactivateUserMutation(email: "${email}", auth0Id: "${auth0Id}") {
                    data
                }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    /**
     *
     * @param {string} auth0Id
     * @param {string} password
     * @returns
     */
    let changePassword = async function (auth0Id, password) {
        const data = JSON.stringify({
            query: `mutation {
                ClientChangePasswordMutation(auth0Id: "${auth0Id}", password: "${password}")
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    }

    return {
        getClient,
        getClientLastTerm,
        setClientContactAccept,
        setClientPartnersAccept,
        setClientTermAccept,
        setClientLastLogin,
        deactivateClient,
        changePassword
    }
}
