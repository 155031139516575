import AuthApiService from '../../services/api/auth.api';
import CGUApiService from '../../services/api/cgu/cgu.api';
import {
    getCurrentCguAuthorization,
    getCurrentDocumentAuthorization
} from '../../services/api/cgu/cgu.authorizations';
import { SET_CGU } from '../mutations/cgu.types';

const state = () => ({
    cgu: null,
})

const mutations = {
    [SET_CGU] (state, payload) {
        state.cgu = payload;
    },
}

const actions = {
    /**
     * 
     * @param {*} context 
     * @param {*} payload 
     * @returns 
     */
    getCurrentCgu() {
        return new Promise((resolve) => {
            let authorization = getCurrentCguAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                CGUApiService(token).getCurrentCgu()
            ).then((response) => {
                resolve(response.data.data.CGUCurrentQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    },
    /**
     * 
     * @param {*} context 
     * @param {*} payload 
     * @returns 
     */
    getCurrentDocument() {
        return new Promise((resolve) => {
            let authorization = getCurrentDocumentAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                CGUApiService(token).getCurrentDocument()
            ).then((response) => {
                resolve(response.data.data.CGUCurrentDocumentQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        })
    }
}

const getters = {
    getCurrentCgu: (state) => state.cgu,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
