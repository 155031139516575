import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './client_scopes';

/**
 *
 * @returns {Authorization}
 */
export function getClientAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.CLIENT_GET);
}

/**
 *
 * @returns {Authorization}
 */
export function getClientLastTermAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.TERM_LAST);
}

/**
 *
 * @returns {Authorization}
 */
export function setClientContactAcceptAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.CLIENT_PATCH);
}

/**
 *
 * @returns {Authorization}
 */
 export function setClientPartnersAcceptAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.CLIENT_PATCH);
}

/**
 *
 * @returns {Authorization}
 */
export function setClientTermAcceptAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.TERM_ACCEPT);
}

/**
 *
 * @returns {Authorization}
 */
export function setClientLastLoginAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.CLIENT_LAST_LOGIN_PUT);
}

/**
 *
 * @returns {Authorization}
 */
 export function deactivateClientAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.DEACTIVATION);
}

/**
 *
 * @returns {Authorization}
 */
 export function changePasswordAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_MS_CLIENT_AUDIENCE, SCOPES.PASSWORD_UPDATE);
}
