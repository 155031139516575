import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/css/root.scss'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/css/all.css'
import '@/assets/css/main.scss'

import { Auth0Plugin } from "./auth";
import { domain, clientId } from "../auth_config.json";
import { HOME_PATH } from "./router/pathes";

createApp(App)
    .use(store)
    .use(router)
    .use(Auth0Plugin, {
        domain,
        clientId,
        onRedirectCallback: appState => {
          router.push(
            appState && appState.targetUrl
              ? appState.targetUrl
              : HOME_PATH
          );
        }
    })
    .mount('#app')
