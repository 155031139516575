/**
 * @param {int} idDossier
 * @returns {string} GraphQL Query
 */
export function getMessagesHistoryQuery(idDossier) {
    return `CoreMessageListQuery(idDossier: ${idDossier}){
        date
        message
    }`;
}
