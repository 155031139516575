const document_scopes = {
    CONTENT_GET : 'document:content:get', // '/document/id/raw-content'
    DOCUMENT_LIST : 'document:document:list', // '/documents'
    CATEGORIE_LIST : 'document:categorie:list', // '/categories'
    DOCUMENT_POST : 'document:document:post', // '/document'
    DOCUMENT_PATCH : 'document:document:patch', // '/document/id'
    DOCUMENT_PUT : 'document:document:put', // '/document/id'
    CONTENT_POST : 'document:content:post', // '/document/id/raw-content'
    SIGNING_ALL : 'document:signing:all', // '/documents/signing'
    DOCUMENT_GET : 'document:document:get', // '/document/id'
    LEGAL_PATCH : 'document:legal:patch', // '/document/juridique/id'
    LEGAL_LIST : 'document:legal:list', // '/document/juridique/dossier/id'
    LEGAL_GET : 'document:legal:get', // '/document/juridique/dossier/id/variable'
};

export default document_scopes;
