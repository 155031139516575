import AuthApiService from '../../services/api/auth.api';
import { getInstance } from '../../auth/authWrapper';
import MessageApiService from '../../services/api/message/message.api';
import {
  getMessagesHistoryAuthorization,
  sendMesageAuthorization
} from '../../services/api/message/message.authorizations';
import { SET_MESSAGES } from '../mutations/messages.types';
import { jsonEscape } from '../../utils/string';

const state = () => ({
  messages: [],
})

const mutations = {
  [SET_MESSAGES] (state, payload) {
    state.messages = payload;
  },
}

const actions = {
  /**
   *
   * @param {*} context
   * @param {*} payload
   * @returns
   */
  getMessagesHistory(context) {
    context.commit(SET_MESSAGES, [])

    const authService = getInstance();

    return new Promise((resolve) => {
      let authorization = getMessagesHistoryAuthorization();
      AuthApiService(authorization.audience, authorization.scope).getToken().then((token) => {
        MessageApiService(token).getMessagesHistory(authService.getUserDossierId())
          .then((response) => {
            context.commit(SET_MESSAGES, response.data.data.CoreMessageListQuery)
            resolve(response.data.data.CoreMessageListQuery);
          })
          .catch(() => { })
      })
    })
  },
  /**
   *
   * @param {*} context
   * @param {*} payload
   * @param {String} payload.message
   * @param {Int} payload.contactId
   *
   * @returns
   */
  sendMesage(context, payload) {
    const authService = getInstance();

    return new Promise((resolve, reject) => {
      let authorization = sendMesageAuthorization();
      AuthApiService(authorization.audience, authorization.scope).getToken().then((token) => {
        MessageApiService(token).sendMesage(
          authService.getUserDossierId(),
          jsonEscape(payload.message),
          payload.contactId
        )
          .then((response) => {
            resolve(response.data.data.CoreSendMessageMutation);
          }).catch((err) => {
            console.error(err);
            alert('Merci de contacter votre commercial');
            reject(err.response.data.errors)
          });
      })
    })
  },
}

const getters = {
  getMessages: (state) => state.messages,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
