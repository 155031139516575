/**
 * @param {int} idUser
 * @returns {string} GraphQL Query
 */
export function getUserQuery(idUser) {
    return `UserQuery(id: ${idUser}){
        id
        firstname
        lastname
        title
        contact{
            phones
            fax
            email
        }
        links{
            id
            type
        }
    }`;
}

/**
 * @param {int} idUser
 * @returns {string} GraphQL Query
 */
export function getUserSuperiorsQuery(idUser) {
    return `UserSuperiorsQuery(id: ${idUser}){
        id
        firstname
        lastname
        title
        contact{
            phones
            fax
            email
        }
        links{
            id
            type
        }
    }`;
}

/**
 *
 * @param {string} applicationId
 * @param {string} applicationName
 * @param {string} email
 * @returns {string} GraphQL Query
 */
export function unblockMutation(applicationId, applicationName, email) {
    return `mutation {
        UserUnblockMutation(
            applicationId: "${applicationId}"
            applicationName: "${applicationName}"
            email: "${email}"
        )
    }`;
}
