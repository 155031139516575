import { Authorization } from '../../graphql/builder/authorization';
import SCOPES from './message_scopes';

/**
 * 
 * @returns {Authorization}
 */
export function getMessagesHistoryAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_ERP_SERVICE_AUDIENCE, SCOPES.MESSAGE_LIST);
}

/**
 * 
 * @returns {Authorization}
 */
export function sendMesageAuthorization() {
    return new Authorization(process.env.VUE_APP_AUTH0_ERP_SERVICE_AUDIENCE, SCOPES.MESSAGE_SEND);
}
