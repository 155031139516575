/**
 * 
 * @param {string} str 
 * @returns {string}
 */
export function jsonEscape(str) {
    return str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
}

/**
 * 
 * @param {string} str 
 * @returns {string}
 */
export function jsonToHtml(str)  {
    return str.replace(/\\n/g, "<br/>").replace(/\\r/g, "").replace(/\\t/g, "\t").replace(/\n/g, "<br/>");
}

/**
 * @param String text
 * @return String
 */
export function replaceVars(str) {
    var inputs = {
        "”N°vert”" : `<a href="tel:${process.env.VUE_APP_NUMERO_VERT_PHONE_NUMBER}">${process.env.VUE_APP_NUMERO_VERT_PHONE_NUMBER}</a>`
    };
    Object.keys(inputs).forEach(key => {
        str = str.replace(`${ key }`, inputs[key]);
    });
    return str;
}
