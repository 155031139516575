import axios from '../../../services/api';
import {
    getDocumentContentQuery,
    getDocumentListQuery,
    getDocumentSigningByDossierQuery,
    getDocumentSigningLinkQuery,
    getLegalDocumentListByDossierQuery,
    patchLegalDocumentMutation
} from '../../graphql/queries/document.queries';

export default (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    /**
     * Get Content of document
     * @param {int} id document’s id
     * @returns 
     */
     let getDocumentContent = async function (id) {
        const data = JSON.stringify({
            query: `query {
                ${ getDocumentContentQuery(id) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Content-Transfer-Encoding': 'BASE64'
            },
            data: data
        };
        return axios(config);
     };

    /**
     * Get List of document
     * @param int idDossier 
     * @returns 
     */
    let getDocumentList = async function (idDossier) {
        const data = JSON.stringify({
            query: `query {
                ${ getDocumentListQuery(idDossier) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    };

    /**
     * Get List of signing document
     * @param {int} idDossier
     * @param {String} status
     */
    let getDocumentSigningByDossier = async function (idDossier, status) {
        const data = JSON.stringify({
            query: `query {
                ${ getDocumentSigningByDossierQuery(idDossier, status) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'DOCUMENT_SIGNING_GET_ALL'
            },
            data: data
        };
        return axios(config);
    };

    /**
     * Get Link of a signing document
     * @param {string} transactionId
     * @param {string} email
     */
     let getDocumentSigningLink = async function (transactionId, email) {
        const data = JSON.stringify({
            query: `query {
                ${ getDocumentSigningLinkQuery(transactionId, email) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
     };

    /**
     * 
     * @param {*} idDossier 
     * @returns 
     */
    let getLegalDocumentList = async function (idDossier) {
        const data = JSON.stringify({
            query: `query {
                ${ getLegalDocumentListByDossierQuery(idDossier) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    };

    /**
     *
     * @param {int} id 
     * @param {int|null} dateConsultation 
     * @param {int|null} dateProvision 
     * @param {int|null} dateValidation 
     * @param {int|null} dateRefus
     * @returns 
     */
    let patchLegalDocument = async function (id, dateConsultation, dateProvision, dateValidation, dateRefus) {
        const data = JSON.stringify({
            query: `mutation {
                ${ patchLegalDocumentMutation(id, dateConsultation, dateProvision, dateValidation, dateRefus) }
            }`,
            variables: {}
        });
        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        return axios(config);
    };

    /**
     *
     * @param {Int} idDocument
     * @param {Array} files
     * @param {String} label
     * @param {String} category
     * @param {Function} category
     * @returns
     */
    let uploadDocument = async function (idDocument, files, label, category, progressHandler) {
        const formData = new FormData();
        formData.append('operations', '{ "query": "mutation ($files: [Upload!]!) { UploadDocumentMutation(idDocument: '+idDocument+', document: {label: \\"'+label+'\\", category: \\"'+category+'\\", files: $files})}", "variables": { "files": [] } }');
        const map = {};
        for (let index = 0; index < files.length; index++) {
            map[index] = [`variables.files.${index}`];
        }
        formData.append('map', JSON.stringify(map));
        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            formData.append(index, file);
        }

        const config = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                // TODO: remove after merge of #3262
                'X-AUTH-ROLES': 'DOCUMENT_PUT_CONTENT_ONE'
            },
            data: formData
        };
        if (typeof progressHandler == 'function') {
            config.onUploadProgress = progressHandler;
        }
        return axios(config);
    };

    return { getDocumentContent, getDocumentList, getDocumentSigningByDossier, getDocumentSigningLink, getLegalDocumentList, patchLegalDocument, uploadDocument }
}
