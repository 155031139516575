import DocumentApiService from '../../services/api/document/document.api';
import AuthApiService from '../../services/api/auth.api';
import { getInstance } from '../../auth/authWrapper';
import {
    getDocumentContentAuthorization,
    getDocumentListByDossierAuthorization,
    getDocumentSigningByDossierAuthorization,
    getDocumentSigningLinkAuthorization,
    getLegalDocumentListByDossierAuthorization,
    getUploadDocumentAuthorization,
    patchLegalDocumentAuthorization
} from '../../services/api/document/document.authorizations';
import document_signing_status from '../../constants/document_signing_status';
import { SET_DOCUMENTS, SET_DOCUMENTS_SIGNING, SET_LEGAL_DOCUMENTS } from '../mutations/document.types';

const state = () => ({
    documents: [],
    documentsSigning: [],
    legalDocuments: []
})

const mutations = {
    [SET_DOCUMENTS] (state, payload) {
        state.documents = payload;
    },
    [SET_DOCUMENTS_SIGNING] (state, payload) {
        state.documentsSigning = payload;
    },
    [SET_LEGAL_DOCUMENTS] (state, payload) {
        state.legalDocuments = payload;
    }
}

const actions = {
    /**
     * 
     * @param {*} context
     * @param {int} id document’s id 
     * @returns 
     */
    getDocumentContent(context, id) {
        return new Promise((resolve) => {
            let authorization = getDocumentContentAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).getDocumentContent(id)
            ).then((response) => {
                resolve(response.data.data.DocumentContentQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * 
     * @param {*} context 
     * @returns 
     */
     getDocumentListByMainDossier(context) {
        const authService = getInstance();
        return actions.getDocumentListByDossier(context, authService.getUserDossierId());
    },
    /**
     * 
     * @param {*} context
     * @param int idDossier 
     * @returns 
     */
    getDocumentListByDossier(context, idDossier) {
        return new Promise((resolve) => {
            let authorization = getDocumentListByDossierAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).getDocumentList(idDossier)
            ).then((response) => {
                context.commit(SET_DOCUMENTS, response.data.data.DocumentByDossierQuery);
                resolve(response.data.data.DocumentByDossierQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * 
     * @param {*} context 
     * @returns 
     */
    getDocumentSigningByMainDossier(context) {
        const authService = getInstance();
        return actions.getDocumentSigningByDossier(
            context,
            {
                idDossier: authService.getUserDossierId(),
                status: [
                        document_signing_status.PROGRESS,
                        document_signing_status.SIGNED,
                        document_signing_status.REFUSED,
                        document_signing_status.EXPIRED
                    ].join('|')
            }
        );
    },
    /**
     * 
     * @param {*} context
     * @param {object} payload
     * @param {int} payload.idDossier
     * @param {string} payload.status
     * @returns 
     */
    getDocumentSigningByDossier(context, { idDossier, status }) {
        return new Promise((resolve) => {
            let authorization = getDocumentSigningByDossierAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).getDocumentSigningByDossier(idDossier, status)
            ).then((response) => {
                context.commit(SET_DOCUMENTS_SIGNING, response.data.data.DocumentSigningByDossierQuery);
                resolve(response.data.data.DocumentSigningByDossierQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * 
     * @param {*} context
     * @param {object} payload
     * @param {string} payload.transactionId
     * @returns 
     */
    getDocumentSigningLink(context, { transactionId }) {
        const authService = getInstance();
        const email = authService.getUserEmail();
        return new Promise((resolve) => {
            let authorization = getDocumentSigningLinkAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).getDocumentSigningLink(transactionId, email)
            ).then((response) => {
                resolve(response.data.data.DocumentSigningLinkQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * 
     * @param {*} context
     * @param {object} payload
     * @param {int} payload.idDossier
     * @returns 
     */
    getLegalDocumentList(context, { idDossier }) {
        return new Promise((resolve) => {
            let authorization = getLegalDocumentListByDossierAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).getLegalDocumentList(idDossier)
            ).then((response) => {
                context.commit(SET_LEGAL_DOCUMENTS, response.data.data.LegalDocumentListByDossierQuery);
                resolve(response.data.data.LegalDocumentListByDossierQuery);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     * 
     * @param {*} context 
     * @returns 
     */
    getLegalDocumentListByMainDossier(context) {
        const authService = getInstance();
        return actions.getLegalDocumentList(
            context,
            {
                idDossier: authService.getUserDossierId()
            }
        );
    },
    /**
     * 
     * @param {*} context
     * @param {object} payload
     * @param {int|} payload.id
     * @param {int|null} payload.dateConsultation
     * @param {int|null} payload.dateProvision
     * @param {int|null} payload.dateValidation
     * @param {int|null} payload.dateRefus
     * @returns 
     */
    patchLegalDocument(context, { id, dateConsultation = null, dateProvision = null, dateValidation = null, dateRefus = null }) {
        return new Promise((resolve) => {
            let authorization = patchLegalDocumentAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).patchLegalDocument(id, dateConsultation, dateProvision, dateValidation, dateRefus)
            ).then((response) => {
                const legalDoc = response.data.data.LegalDocumentMutation;
                actions.replaceLegalDocument(context, { legalDoc });
                resolve(legalDoc);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    },
    /**
     *
     * @param {*} context
     * @param {*} legalDoc 
     */
    replaceLegalDocument(context, { legalDoc }) {
        let legalDocs = [...context.state.legalDocuments];
        let index = legalDocs.findIndex(doc => doc.id === legalDoc.id);
        if (index !== -1) {
            legalDocs.splice(index, 1, legalDoc);
            context.commit(SET_LEGAL_DOCUMENTS, legalDocs);
        }
    },
    /**
     * 
     * @param {*} context
     * @param {*} payload
     * @param {Int} payload.idDocument
     * @param {Array} payload.files
     * @param {String} payload.label
     * @param {String} payload.category
     * @param {Function} payload.progressHandler
     *
     * @returns Promise<string>
     */
    uploadDocument(context, payload) {
        return new Promise((resolve) => {
            let authorization = getUploadDocumentAuthorization();
            AuthApiService(authorization.audience, authorization.scope).getToken().then((token) =>
                DocumentApiService(token).uploadDocument(
                    payload.idDocument,
                    payload.files,
                    payload.label,
                    payload.category,
                    payload.progressHandler
                )
            ).then((response) => {
                resolve(response.data.data.UploadDocumentMutation);
            }).catch((err) => {
                console.error(err);
                alert('Merci de contacter votre commercial');
            });
        });
    }
}

const getters = {
    getDocuments: (state) => state.documents,
    getDocumentsSigning: (state) => state.documentsSigning,
    getLegalDocuments: (state) => state.legalDocuments
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
