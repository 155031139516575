/**
 * @param {int} idClient
 * @param {int} idDossier
 * @returns {string} GraphQL Query
 */
export function getClientQuery(idClient, idDossier) {
    return `ClientQuery(id: ${idClient}, idDossier: ${idDossier}){
        id
        firstname
        maidenSurname
        lastname
        title
        contact {
            phones
            fax
            email
            acceptEmailing
            acceptSms
            acceptPartners
        }
        links {
            type
            id
        }
        deactivation {
            date
        }
    }`;
}

/**
 * @param {int} idClient
 * @returns {string} GraphQL Query
 */
export function getClientLastTermQuery(idClient) {
    return `LastTermQuery(id: ${idClient}){
        id
    }`;
}

/**
 * @param {int} idClient
 * @param {int} idDossier
 * @returns {string} GraphQL Query
 */
export function getClientAdvisorQuery(idClient, idDossier) {
     return `ClientQuery(id: ${idClient}, idDossier: ${idDossier}) {
        id
        links {
            type
            id
        }
        rc {
            id
            firstname
            lastname
            title
            contact{
                phones
                fax
                email
            }
            links{
                id
                type
            }
            local{
                id
                type
                address {
                    type
                    label
                    postalCode
                    town
                    country
                }
            }
        }
    }`;
}
